import React, { useEffect } from 'react';
import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import './App.css';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { JWT } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
//import * as http from 'http'



type CountryObject = {
  [key: string]: CountryDetails
}

type CountryDetails = {
  name: string,
  errors: number
}

type CountryDetailsPerError = {
  name: string,
  code: string
}

function App() {
  const { authStatus } = useAuthenticator(context => [context.authStatus])
  return (
    <>
      {authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} /> : <Quiz />}
    </>

  );
}

function Home() {
  const [date, setDate] = React.useState<Dayjs | null>(dayjs())
  const [cat, setCat] = React.useState<Array<String> | undefined>(undefined)
  const { user, signOut } = useAuthenticator((context) => [context.user])


  //const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  return (
    <Stack>
      <DatePicker value={date} onChange={(newDate) => setDate(newDate)}
        format='DD. MMMM YYYY' disableFuture />

      <Autocomplete options={['a', 'b', 'c', 'abc']} renderInput={params => <TextField {...params} />}
        freeSolo multiple value={cat} disableCloseOnSelect />
      <Button onClick={signOut}>{user ? user.username : 'Logout'}</Button>
    </Stack>

  )
}
function setNewCurrentCodeList(countryCodes: any, setCurrentCodeList: any): number {
  console.log(countryCodes)
  let listPerError: any[] = []
  let highestError = 0
  Object.keys(countryCodes).forEach(c => {
    let currErr = countryCodes[c]['errors']
    if (currErr > highestError) highestError = currErr
    if (!listPerError[currErr]) listPerError[currErr] = []
    listPerError[currErr].push({ 'name': countryCodes[c]['name'], 'code': c })
  })
  console.log(listPerError)
  setCurrentCodeList(listPerError[highestError])
  return highestError
}







function AnswerButton({ isCorrect, handleClick, text, code, show }:
  {
    isCorrect: boolean, handleClick: (isCorrect: boolean, code: string) => void,
    text: string, code: string, show: boolean
  }) {

  const [isClicked, setIsClicked] = React.useState<boolean>(false)
  useEffect(() => { setIsClicked(false) }, [code, text])
  return (<Button color={!isClicked && !show ? 'primary' : (isCorrect ? 'success' : 'error')}
    onClick={() => {
      handleClick(isCorrect, code)
      setTimeout(() => {
        
        setIsClicked(false)
      }, isCorrect ? 300 : 1000)
      setIsClicked(true)
    }}>{text}</Button>)

}

function Quiz() {
  const [idToken, setIdToken] = React.useState<JWT | undefined>()
  const [countryCodes, setCountryCodes] = React.useState<CountryObject>({})
  //const [accessToken, setAccessToken] = React.useState<JWT | undefined>()
  const [currentCodeList, setCurrentCodeList] = React.useState<CountryDetailsPerError[]>([])

  const [randomCountry, setRandomCountry] = React.useState<CountryDetailsPerError>()
  const [shuffledCountries, setShuffledCountries] = React.useState<string[]>()
  const [showCorrect, setShowCorrect] = React.useState<boolean>(false)
  const [currentErrorLevel, setCurrentErrorLevel] = React.useState<number>(0)

  useEffect(() => {
    setRandomCountry(currentCodeList[Math.floor(Math.random() * currentCodeList.length)])
  }, [currentCodeList])

  useEffect(() => {
    if (!randomCountry) return
    const countries = []
    countries.push(randomCountry['code'])
    const codes = Object.keys(countryCodes)

    for (let i: number = 0; i < 3; i++) {
      const tempCountry: any = codes[Math.floor(Math.random() * codes.length)]
      if (countries.indexOf(tempCountry) === -1) {
        countries.push(tempCountry)
      } else {
        i--
      }
    }
    setShuffledCountries(countries.sort((a, b) => 0.5 - Math.random()))
  }, [randomCountry])


  useEffect(() => {
    (async () => {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      //setAccessToken(accessToken)
      setIdToken(idToken)
    })();
  }, [])

  useEffect(() => {
    if (Object.keys(countryCodes).length === 0 || !currentCodeList || currentCodeList.length !== 0) return;
    setCurrentErrorLevel(setNewCurrentCodeList(countryCodes, setCurrentCodeList))
  }, [currentCodeList, countryCodes])


  useEffect(() => {
    if (!idToken) {
      console.log('NO FETCH')
      return;
    }
    console.log('FETCH')
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Authorization', idToken ? idToken.toString() : '')
    //requestHeaders.set('Origin', 'http://localhost:3000')

    fetch('https://api.nechen.de/getFlags', {
      headers: requestHeaders
    }).catch(err => console.error(err)).then(response => response ? response.json() : '').then(data => {
      let temp: any = {}
      Object.keys(data).forEach(e => {
        //temp[e] = { 'name': e.indexOf('us-') == -1 ? data[e] : `US-${data[e]}`, 'errors': 0 }
        if(e.indexOf('us-') === -1){
        temp[e] = { 'name': e.indexOf('us-') === -1 ? data[e] : `US-${data[e]}`, 'errors': 0 }
        }
      })
      setCountryCodes(temp)
    })
  }, [idToken])

  function handleAnswerClick(isCorrect: boolean) {
    const c:string = randomCountry?.code ?? ''
    setShowCorrect(true)
    setTimeout(()=>{
    setShowCorrect(false)
    console.log(`Deleting Element ${c}. Currently ${currentCodeList.length} elements in list.`)
    console.log(currentCodeList)
    setCurrentCodeList(currentCodeList.filter((e: CountryDetailsPerError) => e.code !== c))

    setCountryCodes((prev: CountryObject) => ({
      ...prev,
      [c]: {
        ...prev[c],
        errors: isCorrect ? (prev[c].errors <= 0 ? 0 : prev[c].errors - 1) : prev[c].errors +1
      }
    }))

    },isCorrect ? 300 : 1000)

  }
  useEffect(() => {
    console.log(`Post Delete - ${currentCodeList.length} elements remaining.`)
    console.log(currentCodeList)
    
  }, [currentCodeList])


  return (<>
    {currentCodeList && currentCodeList.length > 0 && randomCountry &&
      shuffledCountries && countryCodes &&
      <Stack>
        <h1>{`${currentCodeList.length} - Error Level ${currentErrorLevel}`}</h1>
        <Flag code={randomCountry.code} alt={randomCountry.name} />
        {shuffledCountries.map(e => {
          const isCorrect = e === randomCountry.code
          return (<AnswerButton text={countryCodes[e].name} handleClick={handleAnswerClick} isCorrect={isCorrect} code={e} show={isCorrect && showCorrect} />)
        })}
      </Stack>
    }
  </>)


}

function Flag({ code, alt }: { code: string, alt: string }) {
  return (
  <img src={`https://flagcdn.com/w160/${code}.png`}
  //<img src={`https://flagcdn.com/256x192/${code}.png`}
    srcSet={`https://flagcdn.com/w320/${code}.png 2x`}
    //height="192"
    width="320"
    alt={alt} 
    style={{border: "5px solid"}}/>)
}


export default App;
