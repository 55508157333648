import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Button, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/de'
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
Amplify.configure({
  Auth: {
    Cognito: {
      //region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID
    }
     
  }
})
root.render(
  <React.StrictMode>
    <CssBaseline/>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
      <Authenticator.Provider>
        <App/>
        
        </Authenticator.Provider>
      
    </LocalizationProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
